import React, { useEffect, useState } from "react";
import { getTimeLogData, postTimeLogData } from "../../lib/network/api";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useModal } from "../../lib/contexts/ModalContext";

const ActivityTable = ({ task_id, userId, setActivityList, activityList }) => {
  const { cardId, getActivityLog, loader } = useModal();
  const validationSchemaTimeLog = Yup.object({
    taskDescription: Yup.string()
      .required("Task description is required")
      .min(5, "Task description must contain at least 5 characters")
      .max(200, "Task description exceed 200 characters")
      .matches(/^[^\s][a-zA-Z0-9\s\-.,!"'?/()_$%&:;<>{}]*$/, {
        message: "Only alphanumeric are allowed",
      }),
    timeSpent: Yup.string()
      .required("Time spent is required")
      .matches(/^\d{2}:\d{2}$/, "Invalid time format. Please use HH:MM format"),
  });
  const handleTimeSpentChange = (event) => {
    const { name, value } = event.target;
    // Format the input value to allow only numbers
    const formattedValue = value.replace(/\D/g, "");
    // Insert a colon after two digits
    let formattedTime = formattedValue.replace(
      /(\d{2})(\d{0,2})/,
      (match, firstTwoDigits, remaingTwoDigits) => {
        if (parseInt(firstTwoDigits) <= 23) {
          if (remaingTwoDigits <= 60) {
            return `${firstTwoDigits}:${remaingTwoDigits.slice(0, 2)}`;
          } else {
            return firstTwoDigits;
          }
        } else {
          return "23";
        }
      }
    );
   // Limit input to 5 characters (including colon)
  if (formattedTime.length > 5) {
    formattedTime = formattedTime.slice(0, 5);
  }
  formikTimeLog.setFieldValue(name, formattedTime);
};




  const formikTimeLog = useFormik({
    initialValues: {
      taskDescription: "",
      timeSpent: "",
    },
    validationSchema: validationSchemaTimeLog,
    onSubmit: async (values) => {
      const trimmedTaskDescription = values.taskDescription.trim();
      try {
        const data = {
          task_id,
          time_logged: values.timeSpent,
          activity: trimmedTaskDescription,
          user_id: userId,
        };
        await postTimeLogData(data);
        getActivityLog(cardId);
        formikTimeLog.resetForm();
      } catch (error) {
      }
    },
  });
  return (
    <form onSubmit={formikTimeLog.handleSubmit}>
      <div className="row">

        <div className="col-lg-8 mb-2 mb-lg-0">
          <label className="form-label text-start w-100">What you did for this task?</label>

          <textarea
            className={`form-control ${formikTimeLog.touched.taskDescription &&
              formikTimeLog.errors.taskDescription
              ? "is-invalid"
              : ""
              }`}
            name="taskDescription"
            value={formikTimeLog.values.taskDescription}
            onChange={formikTimeLog.handleChange}
            onBlur={formikTimeLog.handleBlur}
            cols="30"
            rows="10"
            placeholder="Enter task here"
          ></textarea>
          {formikTimeLog.touched.taskDescription &&
            formikTimeLog.errors.taskDescription && (
              <div className="invalid-feedback">
                {formikTimeLog.errors.taskDescription}
              </div>
            )}
        </div>
        <div className="col-lg-4">
          <label className="form-label text-start w-100">How much time spent</label>
          <div className="d-flex align-top">
            <div style={{ flex: "1" }}>
              <input
                type="text"
                className={`form-control ${formikTimeLog.touched.timeSpent &&
                  formikTimeLog.errors.timeSpent
                  ? "is-invalid"
                  : ""
                  }`}
                id="timeSpent"
                name="timeSpent"
                value={formikTimeLog.values.timeSpent}
                onChange={handleTimeSpentChange}
                onBlur={formikTimeLog.handleBlur}
                placeholder="HH:MM"
              />
              {formikTimeLog.touched.timeSpent &&
                formikTimeLog.errors.timeSpent && (
                  <div className="invalid-feedback">
                    {formikTimeLog.errors.timeSpent}
                  </div>
                )}
            </div>
            <button
              className="btn btn-primary ms-2"
              type="submit"
              disabled={!formikTimeLog.isValid}
            >
              Save
            </button>

          </div>
        </div>
      </div>
      {!loader ? (
        <div className="table-responsive mt-3">
          <table className="table">
            <thead>
              <tr>
                <th className="text-start">Member Name</th>
                <th className="text-start">Activity</th>
                <th className="text-start">Date</th>
                <th className="text-start">Time spent</th>
              </tr>
            </thead>
            <tbody>
              {activityList.length ? (
                activityList.map((activity, index) => (
                  <tr key={index}>
                    <td className="text-start">{activity.user_info.user_name}</td>
                    <td className="text-start">{activity.activity}</td>
                    <td className="text-start">{new Date(activity.createdAt).toLocaleDateString()}</td>
                    <td className="text-start">{activity.time_logged}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4}>
                    <div className="text-center">No Record Found</div>
                  </td>
                </tr>
              )}
              {activityList.length > 0 && (
                <tr className="total">
                  <td></td>
                  <td></td>
                  <td>Total</td>
                  <td>{(activityList[0].totalTime)}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <div
          className="modal-body-loading"
          style={{ position: "relative", top: "6rem" }}
        >
          Loading...
        </div>
      )}
    </form>
  );
};

export default ActivityTable;
