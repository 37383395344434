import React, { useState, useEffect } from 'react';
import Layout from '../layout/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { API_URL } from "../../lib/network/api";
import { useNavigate } from 'react-router-dom';
import Loader from "../../components/Loader/Loader";
import Select from "react-select";
import { useFormik } from 'formik';

const Archive = () => {
  const [streams, setStreams] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [isAll, setIsAll] = useState(localStorage.getItem('isAll') === 'true');
  const [loader, setLoader] = useState(false);
  const [selectedStream, setSelectedStream] = useState("all")
  const loggedinUser = JSON.parse(localStorage.getItem("loggedinUser"));
  const userId = loggedinUser.user_id;
  const userRole = loggedinUser?.role;
  const isAllowedRole = ["PM", "TL", "TM"].includes(userRole);
  let tableKeyCount = 1;
  // const selectedStream = localStorage.getItem("selectedStream");
  const navigate = useNavigate();
  // const formik = useFormik({ initialValues: { stream: selectedStream } });
  const formik = useFormik({ initialValues: { stream: "all" } });


  useEffect(() => {
    fetchData(isAll);
  }, []);

  
  useEffect(() => {
    const fetchStreams = async () => {
      try {
        const response = await fetch(`${API_URL}/task/stream`);
        const data = await response.json();
        setStreams(data.data);
      } catch (error) {
        console.error('Error fetching streams:', error);
      }
    };
    fetchStreams();
  }, []);
  const handleTaskDetails = (task) => {
    const { task_id } = task;
    navigate(`/task-details?id=${task_id}`);
  };

  const handleMyTasksCheckboxChange = async (e) => {
    const isChecked = e.target.checked;
    setIsAll(isChecked);
    localStorage.setItem('isAll', isChecked);
    fetchData(isChecked, formik.values.stream); // Pass the selected stream here
  };
  

  const handleStreamChange = (selectedOption) => {
    const selectedStreamId = selectedOption?.value;
    // localStorage.setItem("selectedStream", selectedStreamId);
    formik.setFieldValue('stream', selectedStreamId);
    fetchData(isAll, selectedStreamId);
  };


const fetchData = async (isChecked, streamId) => {
  const streamQueryParam = streamId && streamId !== 'all' ? `&stream=${streamId}` : '';
  setLoader(true);
  try {
    const response = await fetch(`${API_URL}task/get2WeeksOldTasks?userId=${userId}&alltask=${isChecked || false}${streamQueryParam}`);
    const data = await response.json();
    setTasks(data.data);
  } catch (error) {
    console.error('Error fetching tasks:', error);
  } finally {
    setLoader(false);
  }
};



  const removeHtmlTags = (value) => {
    if (!value) return '';
    return value.replace(/<[^>]*>/g, "");
  };
  const defaultStream = selectedStream ? streams.find(stream => stream.stream_id === selectedStream) : null;

  return (
    <Layout>
      <div className="container-fluid">
        <div className="header-tp-action"><h4 className="mb-2">Completed Archive Task</h4></div>
        <div className='filter_bar d-flex flex-wrap ms-md-auto align-items-center'>
          {isAllowedRole && (
            <div className="form-check ms-3">
              <div className="form-check ">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="task_priority"
                  id="task_priority_high"
                  value="H"
                  checked={isAll}
                  onChange={handleMyTasksCheckboxChange}
                />
                <label
                  className="form-check-label"
                  htmlFor="task_priority_high"
                >
                  All Task
                </label>
              </div>
            </div>
          )}
          <div className="d-flex align-items-center ms-3 col-md-6 col-lg-4" >
            <label htmlFor="stream" className="form-label mb-0 me-2">
              Stream
            </label>

            <Select
            className='stream-select-width'
              name="stream"
              value={{ value: formik.values.stream, label: formik.values.stream === 'all' ? 'All Streams' : streams.find(stream => stream.stream_id === formik.values.stream)?.stream_name }}
              onChange={handleStreamChange}
              options={[
                { value: 'all', label: 'All Streams' },
                ...streams.map(stream => ({ value: stream.stream_id, label: stream.stream_name }))
              ]}
              isSearchable={true}
              placeholder="Search or select stream"
            />




            {formik.touched.stream && formik.errors.stream && (
              <div className="invalid-feedback">
                {formik.errors.stream}
              </div>
            )}
          </div>
        </div>
        <div className="comp-task-cont">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Task ID</th>
                <th scope="col">Title</th>
                <th scope="col">Stream Name</th>
                <th scope="col">Details</th>
                <th scope="col">Assigned To</th>
                <th scope="col">Priority</th>
                <th scope="col">Days Since Update</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(tasks) ? tasks.map(task => (
                <tr key={tableKeyCount++}>
                  <td>{task.task_id}</td>
                  <td>{task.task_title}</td>
                  <td>{task.stream_name}</td>
                  <td>{removeHtmlTags(task?.task_details?.length > 60 ? task.task_details.substring(0, 100) + '...' : task.task_details)}</td>
                  <td>{task.task_assigned_to}</td>
                  <td>{task.task_priority}</td>
                  <td>{task.task_update_days}</td>
                  <td>
                    <button type="button" className="btn btn-link" onClick={() => handleTaskDetails(task)}>
                      <FontAwesomeIcon icon={faEye} />
                    </button>
                  </td>
                </tr>
              )) :
                <td colspan="8" style={{ textAlign: 'center' }}> No Data Found  </td>
              }
            </tbody>
          </table>
        </div>
      </div>
      {loader && <Loader />}
    </Layout>
  );
};

export default Archive;

