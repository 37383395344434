import React, { useEffect, useLayoutEffect, useState } from "react";
import Layout from "../layout/Layout";
import { getTaskList, updateTaskStatusCycle, getStreamPriorityFilterDetails, API_URL, getStreamsData, } from "../../lib/network/api";
import { useLocation, useNavigate } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TaskCard from "./TaskCard";
import { transformData } from "./TransformData";
import Loader from "../Loader/Loader";
import ConfirmationModal from "./ConfirmationModal";
import CustomModal from "../CustomComponents/CustomModal";
import closeCircle from "../../assets/images/close-circle.svg";
import { useModal } from "../../lib/contexts/ModalContext";
import axios from "axios";
import { useMainContext } from "../../lib/contexts/context";
import { Link } from 'react-router-dom';
import addBtn from "../../../src/assets/images/add-circle-btn.svg"

const MyTasks = () => {
  const { tagClicked, handleClickedTag } = useMainContext();
  const [selectedTag, setSelectedTag] = useState("");
  const [taskData, setTaskData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [streams, setStreams] = useState([]);

  const [priorityList, setPriorityList] = useState([]);
  const [selectedStream, setSelectedStreams] = useState("");
  const [tags, setTags] = useState([]);
  const [selectedPrioirty, setSelectedPrioirty] = useState("");
  const [modalState, setToggalModalState] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [shouldMove, setShouldMove] = useState(false);
  const [sourceData, setSourceData] = useState({});
  const [destinationData, setDestinationData] = useState({});
  const { setUserId, userReportingTo, setUserReportingTo, userName, setUserName, } = useModal();
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isAllTaskChecked, setIsAllTaskChecked] = useState(localStorage.getItem('isAllTaskChecked') === 'true');
  const navigate = useNavigate();
  const location = useLocation();
  const loggedinUser = localStorage.getItem("loggedinUser");
  const userData = JSON.parse(loggedinUser);
  const userId = userData.user_id;
  const userRole = userData?.role;
  const isAllowedRole = ["PM", "TL", "TM"].includes(userRole);
  const queryParams = new URLSearchParams(location.search);
  const selectedTaginTagsCloud = queryParams.get("tag");

  const setBoardData = async (data) => {
    if (data) {
      const stream_res = await getStreamsData();
      const prioirty_data = [
        { p: "High", p_id: "H" },
        { p: "Medium", p_id: "M" },
        { p: "Low", p_id: "L" },
      ];
      if (data.length > 0) {
        const transformedData = transformData(data);
        setTaskData(transformedData);
        setStreams(stream_res.data.data);
        setPriorityList(prioirty_data);
      } else {
        const transformedData = transformData(data);
        setTaskData(transformedData);
        setStreams(stream_res.data.data);
        setPriorityList(prioirty_data);
      }
    } else {
    }
  }
  const filteredTasks = taskData.filter((task) =>
    task.title && task.title.toLowerCase().includes("".toLowerCase())
  );
  useEffect(() => {
    // Update selectedStream state when the route changes
    const storedStream = localStorage.getItem("selectedStream");
    if (storedStream) {
      setSelectedStreams(Number(storedStream));
    }
    const storedMember =localStorage.getItem("selectedMember")
    if(storedMember){
      setSelectedMember(storedMember)
      setIsAllTaskChecked(false);
      localStorage.setItem('isAllTaskChecked', false)
    }
    const storedPriority = localStorage.getItem("selectedPriority")
    if(storedPriority){
      setSelectedPrioirty(storedPriority)
    }
    console.log("state of selectedPrioirty in use Effect ",selectedPrioirty);
  }, [location.pathname , selectedStream , selectedPrioirty]); 

  const handleMemberChange = async (selectedMemberId) => {
    setSelectedMember(selectedMemberId);
    localStorage.setItem("selectedMember",selectedMemberId)
    setIsAllTaskChecked(false);
    const priorityLevel = priorityList.find(
      (priority) => priority.p === selectedPrioirty
    )?.p_id;
    const streamValue = selectedStream !== "" ? selectedStream : "";
    
    const params = {
      userId: userId,
      memberId: selectedMemberId,
      filter1: priorityLevel ? priorityLevel : "",
      filter2: streamValue ? streamValue : "",
    };
    if (!isAllTaskChecked) {
      params.myTask = true;
    }
    getStreamPriorityFilterDetails(params)
      .then((res) => {
        if (res.data != null) {
          setBoardData(res.data.data);
        } else {
          setBoardData([]);
        }
      })
      .catch((err) => {
        setBoardData([]);
      });
  };
  const fetchMembers = async () => {
    try {
      const response = await axios.get(`${API_URL}/task/getAllUserNames`);
      if (response.data && response.data.data) {
        setMembers(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching members:", error);
    }
  };
  useEffect(() => {
    fetchTags();
    fetchMembers();
    const userid = JSON.parse(localStorage.getItem("loggedinUser"));
    if (userid.user_id) {
      setUserId(userid.user_id);
      setUserName(userid.user_name);
      setUserReportingTo(userid.user_reporting_to);
    }
  }, []);
  useLayoutEffect(() => {
    getMyTaskList();
  }, [userId]);
  const fetchTags = async () => {
    try {
      const response = await axios.get(`${API_URL}/task/get_all_tags`);
      if (response.data && response.data.data) {
        setTags(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching tags:", error);
    }
  };
  const getMyTaskList = async (tag) => {
    setLoader(true);

    const stream_res = await getStreamsData();
    setStreams(stream_res.data.data);

    const prioirty_data = [
      { p: "High", p_id: "H" },
      { p: "Medium", p_id: "M" },
      { p: "Low", p_id: "L" },
    ];
    setPriorityList(prioirty_data);



    const priorityLevel = prioirty_data.find(
      (priority) => priority.p === selectedPrioirty )?.p_id;
     

    const streamValue = selectedStream !== "" ? selectedStream : "";

    let params;
    params = {
      userId: userId,
      myTask: true,
      filter1: priorityLevel ? priorityLevel : "",
      filter2: streamValue ? streamValue : "" ,
      memberId: selectedMember || "",
      search: selectedTaginTagsCloud || "",
      // title: title || "",

    };
    if (tagClicked != null) {
      params.tag = tagClicked;
    }

    const storedStream = localStorage.getItem("selectedStream");
    if (storedStream) {
      params.filter2 = Number(storedStream);
    }
    const storedMember = localStorage.getItem("selectedMember");
    if(storedMember) {
      params.memberId = storedMember;
    }
    let storedPriority = localStorage.getItem("selectedPriority");
    if(storedPriority) {
       storedPriority = prioirty_data.find(
        (priority) => priority.p === storedPriority )?.p_id;
   
      params.filter1 = storedPriority;
    }
    setTimeout(() => {
      getTaskList(params)
        .then(async (res) => {
          setBoardData(res.data.data);
        })
        .catch((err) => {
          if (err.response && err.response.status === 404) {
            setTaskData([]);
          } else {
            console.error("Error fetching tasks:", err);
            setBoardData([]);
          }
        })
        .finally(() => {
          setLoader(false);
        });
    }, 1000)
  };

  const mapDroppableIdToStatus = (task_current_status) => {
    switch (task_current_status) {
      case "0":
        return {
          setBoardStatus: 0,
          currentBoard: "To Do",
        };
      case "1":
        return {
          setBoardStatus: 1,
          currentBoard: "In Progress",
        };
      case "2":
        return {
          setBoardStatus: 2,
          currentBoard: "On Hold",
        };
      case "3":
        return {
          setBoardStatus: 3,
          currentBoard: "Done by member (In Review)",
        };
      case "4":
        return {
          setBoardStatus: 4,
          currentBoard: "Completed/ Approved",
        };
      default:
        return {
          setBoardStatus: -1,
          currentBoard: "",
        };
    }
  };
  const onDragEnd = async (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    await testFunction(source, destination);
    const priorityLevel = priorityList.find(
      (priority) => priority.p === selectedPrioirty
    )?.p_id;
    const streamValue = selectedStream !== "" ? selectedStream : "";
    const params = {
      userId: userId,
      filter1: priorityLevel ? priorityLevel : "",
      filter2: streamValue ? streamValue : "",
    };
    if (selectedTag !== "") {
      params.tag = selectedTag;
    }
    if (!isAllTaskChecked) {
      params.myTask = true;
    }
    getStreamPriorityFilterDetails(params)
      .then((res) => {
        if (res.data != null) {
          setBoardData(res.data.data);
        } else {
          setBoardData([]);
        }
      })
      .catch((err) => {
        setBoardData([]);
      });
  };
  const handleConfirmation = async (confirmed) => {
    setShowConfirmationModal(false);

    if (confirmed) {
      await testFunction(sourceData, destinationData);
    } else {
      await testFunction(sourceData, sourceData);
    }
  };
  const testFunction = async (s, d) => {
    const updatedTaskData = [...taskData];
    const sourceList = Array.isArray(updatedTaskData[s.droppableId].items)
      ? updatedTaskData[s.droppableId].items
      : [];
    const [movedTask] = sourceList.splice(s.index, 1);
    const destinationIndex = updatedTaskData[d.droppableId].items.length;
    updatedTaskData[d.droppableId].items.splice(destinationIndex, 0, movedTask);
    setTaskData(updatedTaskData);
    const { currentBoard: state } = mapDroppableIdToStatus(d.droppableId);
    const movedTaskData = {
      task_id: movedTask.task_id,
      task_current_status: state,
      task_from: movedTask.task_from,
      task_assigned_to: userId,
      task_changed_by: movedTask.task_owner,
      task_when_changed: movedTask.task_when_changed,
    };
    try {
      const response = await updateTaskStatusCycle(movedTaskData);
    } catch (error) { }
  };
  useEffect(() => {
    if (shouldMove) {
      setShouldMove(false);
      testFunction(sourceData, destinationData);
    } else {
    }
  }, [shouldMove, sourceData, destinationData]);
  useEffect(() => {
    fetchTags();
    const userid = JSON.parse(localStorage.getItem("loggedinUser"));
    if (userid.user_id) {
      setUserId(userid.user_id);
      setUserName(userid.user_name);
      setUserReportingTo(userid.user_reporting_to);
    }
  }, []);
  useLayoutEffect(() => {
    getMyTaskList();
  }, [userId]);
  const handleAddTask = (e) => {
    e.preventDefault();
    navigate("/task", {
      state: { selectedPrioirty, selectedStream },
    });
  };
  const handleSearch = async (query) => {
    // setLoader(true);
    if (query.length) {
      getTaskList({
        userId: userId,
        search: query,
      })
        .then(async (res) => {
          console.log("res search api ****** ", res.data.data)
          setBoardData(res.data.data);
        })
        .catch((err) => {
          if (err.response && err.response.status === 404) {
            setTaskData([]);
          } else {
            console.error("Error fetching tasks:", err);
            setBoardData([]);
          }
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };
  const handleStreamChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedStreams(selectedValue);
    localStorage.setItem("selectedStream", selectedValue);
    const selectedStream = localStorage.setItem("selectedStream", selectedValue)
    const streamValue = selectedValue;
    const priorityLevel = priorityList.find(
      (priority) => priority.p === selectedPrioirty
    )?.p_id;
    const params = {
      userId: userId,
      filter1: priorityLevel ? priorityLevel : "",
      filter2: streamValue ? streamValue : "",
      memberId: selectedMember,
    };
    if (selectedTag != "") {
      params.tag = selectedTag;
    }
    if (!isAllTaskChecked) {
      params.myTask = true;
    }
    getStreamPriorityFilterDetails(params)
      .then((res) => {
        if (res.data != null) {
          setBoardData(res.data.data);
        } else {
          setBoardData([]);
        }
      })
      .catch((err) => {
        setBoardData([]);
      });
  };
  const handlePrioirtChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedPrioirty(selectedValue);
    console.log("setSelectedPrioirty",setSelectedPrioirty)
    localStorage.setItem("selectedPriority",selectedValue)
   const selectedPriority = localStorage.setItem("selectedPriority",selectedValue)
    const priorityLevel = priorityList.find(
      (priority) => priority.p === selectedValue
    )?.p_id;
    const streamValue = selectedStream !== "" ? selectedStream : "";
    const params = {
      userId: userId,
      filter1: priorityLevel ? priorityLevel : "",
      filter2: streamValue ? streamValue : "",
      memberId: selectedMember,
    };
    if (selectedTag != "") {
      params.tag = selectedTag;
    }
    if (!isAllTaskChecked) {
      params.myTask = true;
    }
    getStreamPriorityFilterDetails(params)
      .then((res) => {
        if (res.data != null) {
          setBoardData(res.data.data);
        } else {
          setBoardData([]);
        }
      })
      .catch((err) => {
        setBoardData([]);
      });
  };
  const handleMyTasksCheckboxChange = async (e) => {
    const isChecked = e.target.checked;
    localStorage.setItem("isAllTaskChecked", isChecked)
    setIsChecked(isChecked);
    setIsAllTaskChecked(isChecked);
    setSelectedMember("")
    const priorityLevel = priorityList.find(
      (priority) => priority.p === selectedPrioirty
    )?.p_id;
    setLoader(true);
    try {
      let response, params;
      if (isChecked) {
        params = {
          userId: userId,
          filter1: priorityLevel ? priorityLevel : "",
          filter2: selectedStream ? selectedStream : "",
        };
      } else {
        params = {
          userId: userId,
          filter1: priorityLevel ? priorityLevel : "",
          filter2: selectedStream ? selectedStream : "",
          myTask: true
        };
      }
      getStreamPriorityFilterDetails(params)
        .then((res) => {
          if (res.data != null) {
            setBoardData(res.data.data);
          } else {
            setBoardData([]);
          }
        })
        .catch((err) => {
          setBoardData([]);
        });
      if (response.data && response.data.data) {
        setBoardData(response.data.data);
        setLoader(false);
      } else {
        setBoardData([]);
        setLoader(false);
        alert("No tasks found for this user.");
        // navigate("/my-task");
      }
    } catch (error) {
      console.error("Error fetching tasks:", error);
      setBoardData([]);
      setLoader(false);
    }
  };
  if (loader) {
    return <Loader />;
  }
  const handleModalClick = (e) => {
    e.stopPropagation();
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch(searchQuery);
    }
  };
  return (
    <Layout>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="container-fluid">
          <div className="header-tp-action flex-wrap">
            <h4 className="mb-2">All Tasks
            </h4>
            <div className="search_box ms-3">
              <input name="form-control"
                placeholder="Search..."
                value={searchQuery}
                onKeyDown={handleKeyDown}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <button
                className={`btn ${!searchQuery ? 'btn-default' : 'btn-primary'} ms-2`}
                disabled={!searchQuery}
                onClick={() => handleSearch(searchQuery)}
              >
                Search
              </button>
            </div>
            <div className="filter_bar d-flex flex-wrap ms-md-auto align-items-center">
              {isAllowedRole && (
                <div>
                  <div className="form-check ms-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="task_priority"
                      id="task_priority_high"
                      value="H"
                      checked={isAllTaskChecked}
                      onChange={handleMyTasksCheckboxChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="task_priority_high"
                    >
                      All Task
                    </label>
                  </div>
                </div>
              )}
              {isAllowedRole && (
                <div className="filter_box ms-3">
                  <label htmlFor="memberSelect"> Member :</label>
                  <select
                    id="memberSelect"
                    className="form-select"
                    aria-label="Select Member"
                    value={selectedMember}
                    onChange={(e) => handleMemberChange(e.target.value)}
                  >
                    <option value="" selected>
                      All
                    </option>
                    {members?.map((member, index) => (
                      <option key={index} value={member.user_id}>
                        {member.user_name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <div className="filter_box ms-3">
                <label htmlFor="prioritySelect">Priority  :</label>
                <select
                  id="prioritySelect"
                  className="form-select"
                  aria-label="Priority Select"
                  value={selectedPrioirty}
                  onChange={(e) => handlePrioirtChange(e)}
                >
                  <option value={""} selected>
                    All
                  </option>
                  {priorityList?.map((p, i) => {
                    return (
                      <option key={i} value={p.p}>
                        {p.p}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="filter_box ms-3">
                <label htmlFor="streamSelect"> Stream :</label>
                <select
                  id="streamSelect"
                  className="form-select"
                  aria-label="Select Stream"
                  value={selectedStream}
                  onChange={(e) => handleStreamChange(e)}
                >
                  <option value="" selected>
                    All
                  </option>
                  {streams?.map((stream, index) => {
                    return (
                      <option key={index} value={stream.stream_id}>
                        {stream.stream_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
              </div>
            </div>
          </div>
          <div className="create_Tasks">
            <section className="task_section">
              {loader && <Loader />}
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto' }}>
                <span style={{ color: 'grey', fontSize: '1.2em' }}>{filteredTasks.length ? "" : "No Data Found"}</span>
                {/* <span style={{ color: 'grey', fontSize: '1.2em' }}>{!loader && filteredTasks.length === 0 ? "No Data Found ...Click on Tasks Flight Icon to go on My Tasks" : ""}</span> */}
              </div>
              {filteredTasks?.map((section, sectionIndex) => {
                  const totalCost = section.items.reduce((acc, task) => {
                  const taskCost = task.task_cost ? task.task_cost.replace(/,/g, '') : '0';
                  return acc + (parseFloat(taskCost) || 0);
                }, 0);
                const formattedTotalCost = totalCost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

              const totalTime = section.items.reduce((acc, task) => acc + (parseFloat(task.task_time) || 0), 0);
              return (
                <Droppable
                  key={sectionIndex}
                  droppableId={sectionIndex.toString()}
                >
                  {(provided, snapshot) => (
                    <div
                      className="task_list_whiteBg"
                      key={sectionIndex}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      <div className="task_title d-flex">
                        <h2 className="me-auto">
                          {section.title}
                          {section.title === "To Do" && (
                          <img
                          src={addBtn}
                          alt="Add Task"
                          className=" ms-2"
                          onClick={handleAddTask}
                        />
                        
                          )}
                        </h2>
                        {/* <span className="count_items">{`${section.items.length} items`}</span> */}
                        <span className="task_info">{`${section.items.length} ${section.items.length > 1 ? 'tasks' : 'task'} | $ ${formattedTotalCost} | Hrs ${totalTime} `}</span>
                      </div>
                      {/* <div className="last-up-rw">
                        <div className="mb-0">
                          <span className="fw-bold">Total Cost:</span> £{formattedTotalCost}
                        </div>
                        <div className="assigned_name ms-auto">
                          <span className="text-dark fw-bold">Total Time:</span> {totalTime} Hrs
                        </div>
                      </div> */}
                      {section.title === "Completed/ Approved" ? <Link to="/archive-task">
                        View Archived Tasks
                      </Link> : ""}
                      <div className="scroll_card">
                        {section.items
                          .map((task, taskIndex) => {
                            return (
                              <Draggable
                                key={`${sectionIndex}-${taskIndex}`}
                                draggableId={`${sectionIndex}-${taskIndex}`}
                                index={taskIndex}
                                isDragDisabled={
                                  section.title === "Completed/ Approved" ||
                                  (task.task_owner_id !== userId &&
                                    task.task_assigned_to_id !== userId)
                                }
                              >
                                {(provided) => (
                                  <TaskCard
                                    key={taskIndex}
                                    task={task}
                                    sectionIndex={sectionIndex}
                                    taskIndex={taskIndex}
                                    provided={provided}
                                    userId={userId}
                                    userName={userName}
                                    userReportingTo={userReportingTo}
                                    getMyTaskList={() => {
                                      getMyTaskList();
                                    }}
                                    modalState={modalState}
                                  />
                                )}
                              </Draggable>
                            );
                          })}
                      {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto',height:'100%' }}>
                      <span className="no-data-found">{section.items.length !== 0 ? "" : "No Data Found"}</span> 
              
              </div>  */}
                      </div>
                    </div>
                  )}
                </Droppable>
              );
            })}
          </section>
        </div>
      </div>
       </DragDropContext>
    
    
     
      <CustomModal
        isOpen={showConfirmationModal}
        onClose={() => handleConfirmation(false)}
        title="Time Log Modal"
        footerContent={
          <button onClick={() => handleConfirmation(false)}>Close</button>
        }
      >
        <div
          className="modal-content custom-modal sure-modal"
          onClick={handleModalClick}
        >
          <div className="modal-header">
            <a
              className="close-popup-modal"
              href=""
              onClick={() => handleConfirmation(false)}
            >
              <img
                src={closeCircle}
                alt=""
                data-bs-dismiss="modal"
                className="close-modal-icon"
              />
            </a>
          </div>
          <div className="modal-body text-center">
            <h1
              className="modal-title fs-5 text-center mb-3"
              id="exampleModalToggleLabel"
            >
              Are you sure you want to mark this task completed, as this cannot
              be undone afterwards.
            </h1>
            <button
              className="btn btn-primary me-2"
              onClick={() => handleConfirmation(true)}
            >
              Yes
            </button>
            <button
              className="btn btn-secondary"
              onClick={() => handleConfirmation(false)}
            >
              No
            </button>
          </div>
        </div>
        {loader && <Loader />}
      </CustomModal>
    </Layout>
  );
};

export default MyTasks;

