import React from "react";
export const CommentList = ({ comments }) => {
  return (
    <div className="comment_scroll_list">
      {comments.length ? (
        comments?.map((comment, index) => (
          <div className="msg_box" key={index}>
            <img src={comment.user_details.user_image} alt="" className="Img-round" />
            <div className="user_comment">
              <span>{comment.user_details.user_name}
              <small className="comment_datetime">
                {comment.date} {comment.time}
              </small>
              </span>
              <p style={{ whiteSpace: "pre-wrap" }}>{comment.comment}</p>
       
            </div>
          </div>
        ))
      ) : (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          No Comments
        </div>
      )}
    </div>
  );
};
