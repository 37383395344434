import axios from 'axios';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { API_URL } from '../../lib/network/api';
import Loader from '../Loader/Loader';

const MembersTable = () => {
  const [membersStreamData, setMembersStreamData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState('');
  const [selectedStream, setSelectedStream] = useState([]);
  const [streams, setStreams] = useState([]);
  const [loader, setLoader] = useState(false);
  
  useEffect(() => {
    setLoader(true);
    getMemberStreamData();
    getStreams();
    setLoader(false);
  }, [selectedStream])
  useEffect(() => {
    setCurrentPage(1);
  }, [selectedStream, searchText]);
  const getStreams = async () => {
    const response = await axios.get(`${API_URL}/task/stream`);
    setStreams(response.data.data);
  };
  const getMemberStreamData = async () => {
    const response = await axios.get(`${API_URL}/task/userDatabystream?filterStream=${selectedStream}`);
    setMembersStreamData(response.data.data);
  }
  const handlePageChange = page => {
    setCurrentPage(page);
  };
  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setRowsPerPage(currentRowsPerPage);
    setCurrentPage(currentPage);
  };
  const calculateSequentialCount = (index) => {
    return (currentPage - 1) * rowsPerPage + index + 1;
  };
  const columns = [
    {
      name: 'S.No.',
      selector: (_, index) => calculateSequentialCount(index),
      sortable: true,
    },
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'Total Pending Tasks',
      selector: row => row.totalPendingTasks,
      sortable: true,
    },
    ...membersStreamData?.members?.[0]?.streams?.map(stream => ({
      name: stream?.stream_name,
      selector: row => (row[`streams.${stream.stream_name}.taskCount`] !== undefined) ? row[`streams.${stream?.stream_name}.taskCount`] : '',
      sortable: true,
    })) || [],
  ];
  const filteredData = membersStreamData?.members?.filter((member) => {
    const nameMatches = member.name.toLowerCase().includes(searchText.toLowerCase());
    return nameMatches;
  });
  const sortedMembers = filteredData?.sort((a, b) => b.totalPendingTasks - a.totalPendingTasks);
  const flattenedData = sortedMembers?.map(member => {
    const row = { id: member.id, name: member.name, totalPendingTasks: member.totalPendingTasks };
    member?.streams?.forEach(stream => {
      row[`streams.${stream?.stream_name}.taskCount`] = stream?.taskCount;
    });
    return row;
  });
  return (
    <div className='container-fluid'>
      <div className='header-tp-action flex-wrap mt-3'>
        <h4 class="mb-2"> Teams Tasks</h4>
        <div className='filter_bar d-flex flex-wrap ms-md-auto'>
          <div className='filter_box'>
            <input
              className='form-control search-from'
              type="text"
              placeholder="Search by name"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <div className='filter_box ms-3'>
            <label htmlFor="stream" className="form-label mb-0">
              Stream
            </label>
            <select
              id="stream"
              name="stream"
              className='form-control form-select'
              onChange={(e) => {
                const selectedValue = e.target.value;
                setSelectedStream(selectedValue === "Select" ? "" : selectedValue);
              }}
            >
              <option value="Select" >
                Select
              </option>
              {streams?.map((stream) => {
                return (
                  <option value={stream.stream_name}>
                    {stream.stream_name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
      <DataTable
        columns={columns}
        data={flattenedData}
        pagination
        paginationPerPage={rowsPerPage}
        paginationRowsPerPageOptions={[5, 10, 15]}
        paginationTotalRows={flattenedData?.length || 0}
        onChangeRowsPerPage={handleRowsPerPageChange}
        onChangePage={handlePageChange}
        highlightOnHover
        striped
        responsive
        paginationComponentOptions={{
          noRowsPerPage: true,
        }}
      />
      {loader && <Loader />}
    </div>
  );
};
export default MembersTable;