import React from "react";
import "../Loader/component.css";

const Loader = () => {
  return (
    <div class="loading">
      <div class="column">
        <div class="dot" id="dot1"></div>
        <div class="dot" id="dot2"></div>
        <div class="dot" id="dot3"></div>
      </div>
      <div class="column">
        <div class="dot" id="dot4"></div>
        <div class="dot center-dot" id="dot5"></div>
        <div class="dot" id="dot6"></div>
      </div>
      <div class="column">
        <div class="dot" id="dot7"></div>
        <div class="dot" id="dot8"></div>
        <div class="dot" id="dot9"></div>
      </div>
    </div>
  );
};

export default Loader;
