import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import close from "../../assets/images/close.svg";
import Layout from "../layout/Layout";
import { API_URL } from "../../lib/network/api";
import Loader from "../../components/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Select from "react-select";

const CreateTask = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [streams, setStreams] = useState([]);
  const [selectedLocalStream, setSelectedLocalStream] = useState();
  const [selectedLocalMember, setSelectedLocalMember] = useState();
  const [selectedLocalPriority, setSelectedLocalPriority] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [members, setMembers] = useState([]);
  const [tags, setTags] = useState([]);
  const [taskExistingData, setTaskExistingData] = useState({});
  const [selectedTaggedMembers, setSelectedTaggedMembers] = useState([]);
  const [costInputValue, setCostInputValue] = useState();
  const [selectedMember, setSelectedMember] = useState(null);

  const handleKeyDown1 = e => {
    e.preventDefault();
  };

  const [loader, setLoader] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const loggedinUser = localStorage.getItem("loggedinUser");
  const userData = JSON.parse(loggedinUser);
  var currentDate = new Date();
  var formattedDateToday = currentDate.toISOString().slice(0, 10);
  useEffect(() => {
    const taskId = queryParams.get("id");
    const localStream = localStorage.getItem("selectedStream")
    const localMember = localStorage.getItem("selectedMember")
    const localPriority = localStorage.getItem("selectedPriority")

    setLoader(true);
    if (taskId) {
      fetchTaskData(taskId);
    }

    getStreams();
    getMembers();
    setLoader(false);
    setSelectedLocalStream(parseInt(localStream));
    setSelectedLocalMember(parseInt(localMember));
    if(localPriority == "High"){
      setSelectedLocalPriority('H');
    } else if(localPriority == "Medium"){
      setSelectedLocalPriority('M');
    } else if(localPriority == "Low"){
      setSelectedLocalPriority('L');
    }


  }, []);


  console.log("taskExistingData111", taskExistingData);

  const fetchTaskData = async (taskId) => {
    try {
      const response = await axios.get(
        `${API_URL}/task/taskById?task_id=${taskId}`
      );
      const fetchTaskData = response.data.data;
      if (
        !userData.user_id ||
        fetchTaskData.task_owner_id !== userData.user_id
      ) {
        navigate("/task");
        return;
      }
      // localStorage.removeItem("selectedStream")
      setTaskExistingData(fetchTaskData);
      setTags(fetchTaskData.task_tags);
    } catch (error) { }
  };

  const getStreams = async () => {
    const response = await axios.get(`${API_URL}/task/stream`);
    setStreams(response.data.data);
  };

  const getMembers = async () => {
    const response = await axios.get(`${API_URL}/task/getAllUserNames`);
    setMembers(response.data.data.map(member => ({ value: member.user_id, label: member.user_name })));
  };

  const postTaskData = async (createTaskData) => {
    try {
      const response = await axios.post(
        `${API_URL}/task/create-task`,
        createTaskData
      );
      return response;
    } catch (error) { }
  };
  const updateTaskData = async (updateData) => {
    try {
      const response = await axios.put(
        `${API_URL}/task/task_update`,
        updateData
      );
      return response;
    } catch (error) { }
  };
  const removeHtmlTags = (value) => {
    return value.replace(/<[^>]*>/g, "");
  };
  const formik = useFormik({
    initialValues: {

      stream: selectedLocalStream || taskExistingData.stream || "",
      // member: selectedLocalMember || taskExistingData.task_assigned_to || "",
      member:  taskExistingData.task_assigned_to || "",
      // task_priority: selectedLocalPriority || taskExistingData.task_priority || "",
      task_priority: taskExistingData.task_priority || "",
      task_title: taskExistingData.task_title || "",
      task_eta: taskExistingData.estimation_Date || formattedDateToday,
      task_details: taskExistingData.task_details || "",
      task_tags: "",
      task_cost: taskExistingData.task_cost || "",
      task_time: taskExistingData.task_time || "",
      tagmembers: taskExistingData.allow_members_to_comment?.map(member => ({ value: member.id, label: member.name })) || [],
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      stream: Yup.string()
        .notOneOf([""], "Please select a stream")
        .required("Stream is required"),
      // member: Yup.string()
      //   .notOneOf([""], "Please select a member")
      //   .required("Member is required"),
      task_priority: Yup.string().required("Priority is required"),
      task_title: Yup.string()
        .required("Title is required")
        .max(100, "Title must be at most 100 characters"),

      task_eta: Yup.string().required("ETA is required"),
      task_details: Yup.string()
        .transform(removeHtmlTags)
        .required("Task Detail is required")
        .max(5000, "Task Detail must be at most 5000 characters"),
    }),
    onSubmit: async (values) => {
      const selectedMemberIds = values.tagmembers.map(member => member.value);
      if (!taskExistingData || Object.keys(taskExistingData).length === 0) {
        const taskAssignedTo = values.stream === "20" ? userData?.user_id : (values.member ? parseInt(values.member) : userData?.user_id);
        const createTaskData = {
          task_title: values.task_title,
          task_owner: userData?.user_name,
          task_eta: 0,
          eta_in_date_time: values.task_eta,
          task_priority: values.task_priority,
          stream: values.stream,
          task_details: values.task_details,
          task_assigned_to: taskAssignedTo,
          // task_assigned_to: parseInt(values.member) ,
          task_tags: tags,
          task_owner_id: userData?.user_id,
          task_cost: values.task_cost,
          task_time: values.task_time,
          allow_members_to_comment: selectedMemberIds

        };
        setLoader(true);
        let result = await postTaskData(createTaskData);
        if (!result || !result.data) {
          toast.error("Something went wrong , please try again later!");
          setLoader(false);
          return;
        }

        localStorage.removeItem("selectedPriority");
        localStorage.removeItem("selectedMember");

        localStorage.removeItem("selectedStream");

        navigate("/my-tasks");
      } else {
        console.log("values", values)
        const updateData = {

          id: taskExistingData.task_id,
          task_title: values.task_title,
          task_owner: userData?.user_name,
          task_eta: 0,
          eta_in_date_time: values.task_eta,
          task_priority: values.task_priority,
          stream: values.stream,
          task_details: values.task_details,
          task_assigned_to: parseInt(values.member),
          task_tags: tags,
          task_owner_id: userData?.user_id,
          task_cost: values.task_cost,
          task_time: values.task_time,
          allow_members_to_comment: selectedMemberIds
        };
        setLoader(true);
        const result = await updateTaskData(updateData);
        if (!result || !result.data) {
          toast.error("Something went wrong , please try again later!");
          setLoader(false);

          return;
        }
        localStorage.removeItem("selectedPriority");
        localStorage.removeItem("selectedMember");

        localStorage.removeItem("selectedStream");
        navigate("/my-tasks");
      }
    },
  });
  const handleTimeChange = (e) => {
    let value = e.target.value;
    if (value === '' || value.endsWith('.')) {
      e.target.value = value;
      formik.setFieldValue("task_time", value);
      return;
    }
    value = value.replace(/[^\d.]/g, '');
    const [integerPart, decimalPart] = value.split('.');
    let truncatedIntegerPart = integerPart.slice(0, 3);
    let truncatedDecimalPart = '';
    if (decimalPart) {
      truncatedDecimalPart = decimalPart.slice(0, 2);
    }
    value = `${truncatedIntegerPart}${truncatedDecimalPart ? `.${truncatedDecimalPart}` : ''}`;
    e.target.value = value;
    formik.setFieldValue("task_time", value);
  };
  useEffect(() => {
    if (formik.values.stream) {
      localStorage.setItem("selectedStream", formik.values.stream);
    }
  }, [formik.values.stream]);

  useEffect(() => {
    const storedStream = localStorage.getItem("selectedStream");
    if (storedStream == "20") {

    }
  })

  const handleCostChange = (e) => {
    let value = e.target.value;

    // Allow only digits, commas, and decimals
    let newValue = value.replace(/[^0-9.,]/g, "");
    const [integerPart, decimalPart] = newValue.split(".");

    // Limit integer part to 8 digits, removing existing commas first
    let formattedIntegerPart = integerPart.replace(/,/g, "").slice(0, 8);

    // Add commas for thousands
    formattedIntegerPart = formattedIntegerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Construct the new value with limited decimal part
    newValue = decimalPart !== undefined
      ? `${formattedIntegerPart}.${decimalPart.slice(0, 2)}`
      : formattedIntegerPart;

    setCostInputValue(newValue);
    formik.setFieldValue("task_cost", newValue);
  };

  const handleTaggedMembersChange = (selectedOptions) => {
    setSelectedTaggedMembers(selectedOptions);
    formik.setFieldValue("tagmembers", selectedOptions);
  };
  const getMinDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;
    return `${year}-${month}-${day}`;
  };
  const savedStream = localStorage.getItem("selectedStream");

  function handleKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      const value = e.target.value;
      if (!value.trim()) return;
      setTags([...tags, value]);
      e.target.value = "";
    }
  }

  function handleTagsBlur(e) {
    const value = e.target.value;
    if (!value.trim()) return;
    setTags([...tags, value]);
    e.target.value = "";
  }

  function removeTag(index) {
    setTags(tags.filter((el, i) => i !== index));
  }

  const handleEditorChange = (value) => {
    formik.setFieldValue("task_details", value);
  };

  const handleMemberChange = (selectedOption) => {
    setSelectedMember(selectedOption);
    formik.setFieldValue('member', selectedOption ? selectedOption.value : '');
  };
  console.log("formik", formik);
  return (
    <Layout>
      <div className="container-fluid">
        <div className="header-tp-action">
          <h4 className="mb-2">Create Tasks:</h4>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="whitebg_container create_Tasks">
            <div className="row">
              <div className="col-md-6 col-lg-4">
                <div className="mb-2 mb-md-4 d-flex align-items-center flex-wrap">
                  <label className="form-label mb-0 me-2">Owner Name :</label>
                  <div className="form_text nameBlog">
                    {userData?.user_name}
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="mb-3 mb-md-4 d-flex align-items-center flex-wrap">
                  <label className="form-label mb-0 me-2">
                    Accountable Member :
                  </label>
                  <div className="form_text nameBlog">
                    {userData?.user_reporting_to}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-4">
                <div className="mb-3">
                  <label htmlFor="stream" className="form-label">
                    Stream
                  </label>
                  <Select className="select-single-react"
                    name="stream"
                    value={{ value: formik.values.stream, label: streams.find(stream => stream.stream_id === formik.values.stream)?.stream_name }}
                    onChange={(selectedOption) => formik.setFieldValue('stream', selectedOption?.value)}
                    options={streams.map(stream => ({ value: stream.stream_id, label: stream.stream_name }))}
                    isSearchable={true}
                    placeholder="Search or select stream"
                  />
                  {formik.touched.stream && formik.errors.stream && (
                    <div className="invalid-feedback">
                      {formik.errors.stream}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="mb-3">
                  <label htmlFor="member" className="form-label">
                    Assigned To (Responsible)
                  </label>
                  {savedStream === "20" ? (
                    <div className="form_text nameBlog">
                      {userData?.user_name}
                    </div>
                  ) : (
                    <Select
                      className="select-single-react"
                      name="member"
                      value={{
                        value: formik.values.member,
                        label: members.find((member) => member.value === formik.values.member)?.label,
                      }}
                      onChange={handleMemberChange}
                      options={members}
                      isSearchable={true}
                      placeholder="Search or select member"
                      isDisabled={savedStream === "20"}
                    />
                  )}
                  {formik.touched.member && formik.errors.member && (
                    <div className="invalid-feedback">
                      {formik.errors.member}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="mb-3">
                  <label htmlFor="task_priority" className="form-label">
                    Priority
                  </label>
                  <div className="radio_btn">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="H"
                        checked={formik.values.task_priority === "H"}
                        onChange={() =>
                          formik.setFieldValue("task_priority", "H")
                        }
                        onBlur={formik.handleBlur}
                        name="task_priority"
                        id="task_priority_high"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="task_priority_high"
                      >
                        High
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="task_priority"
                        value="M"
                        id="task_priority_medium"
                        checked={formik.values.task_priority === "M"}
                        onChange={() =>
                          formik.setFieldValue("task_priority", "M")
                        }
                        onBlur={formik.handleBlur}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="task_priority_medium"
                      >
                        Medium
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="task_priority"
                        value="L"
                        id="task_priority_low"
                        checked={formik.values.task_priority === "L"}
                        onChange={() =>
                          formik.setFieldValue("task_priority", "L")
                        }
                        onBlur={formik.handleBlur}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="task_priority_low"
                      >
                        Low
                      </label>
                    </div>
                  </div>
                  {formik.touched.task_priority &&
                    formik.errors.task_priority && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {formik.errors.task_priority}
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-8">
                <div className="mb-3">
                  <label htmlFor="tagmembers" className="form-label">
                    Allow members to comment
                  </label>
                  <Select
                    isMulti
                    name="tagmembers"
                    options={members}
                    className={`react-select ${formik.touched.tagmembers && formik.errors.tagmembers ? "is-invalid" : ""}`}
                    classNamePrefix="react-select"
                    onChange={(selectedOptions) => {
                      if (savedStream !== "20") {
                        handleTaggedMembersChange(selectedOptions);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.tagmembers}
                    isDisabled={savedStream === "20"}
                  />
                  {formik.touched.tagmembers && formik.errors.tagmembers && (
                    <div className="invalid-feedback">
                      {formik.errors.tagmembers}
                    </div>
                  )}
                </div>
              </div>
              {/* <div className="mb-3 col-auto">
                <label htmlFor="task_cost" className="form-label">
                  Cost(£)
                </label>
                <input
                  type="text"
                  className={`form-control ${formik.touched.task_cost && formik.errors.task_cost
                    ? "is-invalid"
                    : ""
                    }`}
                  id="task_cost"
                  name="task_cost"
                  placeholder="Enter Task Cost"
                  onChange={handleCostChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.task_cost}
                />
                {formik.touched.task_cost && formik.errors.task_cost && (
                  <div className="invalid-feedback">
                    {formik.errors.task_cost}
                  </div>
                )}
              </div> */}
              <div className="mb-3 col-auto">
                <label htmlFor="task_cost" className="form-label">
                  Cost($)
                </label>
                <input
                  type="text"
                  className={`form-control ${formik.touched.task_cost && formik.errors.task_cost ? "is-invalid" : ""}`}
                  id="task_cost"
                  name="task_cost"
                  placeholder="Enter Task Cost"
                  onChange={handleCostChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.task_cost}
                />
                {formik.touched.task_cost && formik.errors.task_cost && (
                  <div className="invalid-feedback">
                    {formik.errors.task_cost}
                  </div>
                )}
              </div>
              <div className="mb-3 col-auto">
                <label htmlFor="task_time" className="form-label">
                  Time
                </label>
                <input
                  type="text"
                  className={`form-control ${formik.touched.task_time && formik.errors.task_time
                    ? "is-invalid"
                    : ""
                    }`}
                  id="task_time"
                  name="task_time"
                  placeholder="Enter Task Time (hh.mm)"
                  onChange={handleTimeChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.task_time}
                />

                {formik.touched.task_time && formik.errors.task_time && (
                  <div className="invalid-feedback">
                    {formik.errors.task_time}
                  </div>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-lg-8">
                <div className="mb-3">
                  <label htmlFor="task_title" className="form-label">
                    Task Title
                  </label>
                  <input
                    type="text"
                    className={`form-control ${formik.touched.task_title && formik.errors.task_title
                      ? "is-invalid"
                      : ""
                      }`}
                    id="task_title"
                    name="task_title"
                    placeholder="Enter Task Title"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.task_title}
                  />
                  {formik.touched.task_title && formik.errors.task_title && (
                    <div className="invalid-feedback">
                      {formik.errors.task_title}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="mb-3">
                  <label htmlFor="task_eta" className="form-label">
                    ETA
                  </label>
                  <input
                    type="date"
                    onKeyDown={handleKeyDown1}
                    className={`form-control  ${formik.touched.task_eta && formik.errors.task_eta
                      ? "is-invalid"
                      : ""
                      }`}
                    id="task_eta"
                    name="task_eta"
                    placeholder="ETA"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.task_eta}
                    min={
                      taskExistingData.estimation_Date
                        ? taskExistingData.estimation_Date
                        : getMinDate()
                    }
                  />
                  {formik.touched.task_eta && formik.errors.task_eta && (
                    <div className="invalid-feedback">
                      {formik.errors.task_eta}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <div>
                    <label htmlFor="task_details"> Task Details</label>
                    <ReactQuill
                      style={{ height: "200px", width: "100%" }}
                      value={formik.values.task_details}
                      onChange={(value, delta, source, editor) => {
                        handleEditorChange(value);
                      }}
                      theme="snow"
                    />
                    {formik.touched.task_details &&
                      formik.errors.task_details && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {formik.errors.task_details}
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3 position-relative">
                  <label htmlFor="task_tags" className="form-label">
                    Tags
                  </label>
                  <div className="tags-input-container">
                    {tags?.map((tag, index) => (
                      <div className="tag" key={index}>
                        <span className="text">{tag}</span>
                        <span
                          className="close"
                          onClick={() => removeTag(index)}
                        >
                          <img src={close} alt="close" />
                        </span>
                      </div>
                    ))}
                    <input
                      type="text"
                      onKeyDown={handleKeyDown}
                      onBlur={handleTagsBlur}
                      className="tags-input"
                      id="task_tags"
                      name="task_tags"
                      placeholder="Tags"
                    />
                  </div>
                </div>
              </div>
              <span className="tags">* Press enter to add the tags</span>
            </div>

            <div className="text-end">
              <button className="btn btn-primary">
                {taskExistingData && Object.keys(taskExistingData).length !== 0
                  ? "Update"
                  : "Save"}
              </button>
            </div>
          </div>
        </form>
        <ToastContainer />
      </div>
      {loader && <Loader />}
    </Layout>
  );
};
export default CreateTask;

