import React, { useState, useEffect } from 'react';
import Layout from '../layout/Layout';
import { API_URL } from '../../lib/network/api';


const DetailsTable = () => {
  const [streamsData, setStreamsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchStreamData = async () => {
      try {
        const response = await fetch(`${API_URL}/task/get_stream_data`);
        const responseData = await response.json();
        setStreamsData(responseData.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching stream data:', error);
        setIsLoading(false);
      }
    };

    fetchStreamData();
  }, []);

  // Calculate total count for each row
  const calculateTotal = (rowData) => {
    return rowData.high + rowData.medium + rowData.low;
  };

  return (
    
      <div className='container-fluid'>
        <div class="header-tp-action"><h4 class="mb-2">Members Report</h4></div>
      
          <div className="whitebg_container create_Tasks">
            <div>
              {streamsData.map((stream, index) => (
                <div key={index}>
                  <h5 className='text-start'>{stream.stream_name}</h5>
                  <div className='table-responsive mb-2'>
                    <table className='table'>
                      <thead>
                        <tr>
                          <th style={{width: "40%"}}>Member Name</th>
                          <th style={{width: "15%"}}>High</th>
                          <th style={{width: "15%"}}>Medium</th>
                          <th style={{width: "15%"}}>Low</th>
                          <th style={{width: "15%"}}>Total Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {stream.priority.map((priorityItem, rowIndex) => (
                          <tr key={rowIndex}>
                            <td>{priorityItem.memberName}</td>
                            <td>{priorityItem.high}</td>
                            <td>{priorityItem.medium}</td>
                            <td>{priorityItem.low}</td>
                            <td>{calculateTotal(priorityItem)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}
            </div>
          </div>
       
      </div>

  );
};

export default DetailsTable;
