import React, { createContext, useContext,  useState } from "react";
import { getTaskDetails, getTimeLogData, getAllComments } from "../network/api";

// Create the ModalContext
const ModalContext = createContext();
export const ModalProvider = ({ children }) => {
  const [isCommentsModalOpen, setCommentsModalOpen] = useState(false);
  const [isCardModalOpen, setCardModalOpen] = useState(false);
  const [isTimeLogModalOpen, setTimeLogModalOpen] = useState(false);
  const [cardId, setCardId] = useState(null);
  const [taskDetails, setTaskDetails] = useState(null);
  const [eta, setETA] = useState("");
  const [activityList, setActivityList] = useState([]);
  const [commentList, setCommentList] = useState([]);
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [userReportingTo, setUserReportingTo] = useState("");
  const openCommentsModal = () => setCommentsModalOpen(true);
  const closeCommentsModal = () => setCommentsModalOpen(false);
  const openCardModal = () => setCardModalOpen(true);
  const closeCardModal = () => setCardModalOpen(false);
  const [loader, setLoader] = useState(false);
  const openTimeLogModal = () => setTimeLogModalOpen(true);
  const closeTimeLogModal = () => setTimeLogModalOpen(false);
  const closeModal = () => {
    closeCardModal(false);
    closeCommentsModal(false);
    closeTimeLogModal(false);
    // getMyTaskList();
  };
  const handleTimeLogOpen = () => {
    setTimeLogModalOpen(true);
    closeCardModal(false);
  };
  const handleToggalModalState = () => {
    if (isCardModalOpen || isTimeLogModalOpen) {
      closeCardModal();
      closeTimeLogModal();
    }
  };
  const addComment = (t_id) => {
    const params = {
      task_id: t_id,
      // task_id:task.task_id,
      user_id: userId,
    };
    setLoader(true);
    getAllComments(params)
      .then((res) => {
        if (res.data && res.data.data) {
          setCommentList(res?.data?.data);
        } else {
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          setCommentList([])
        } else {
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const fetchTaskDetails = (t_id) => {
    setLoader(true);
    const param = {
      task_id: t_id,
    };
    getTaskDetails(param)
      .then((res) => {
        setTaskDetails(res.data.data);
        setETA(res.data.data.estimation_Date);
        addComment(t_id)
      })
      .catch((err) => {
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const getActivityLog = async (t_id) => {
    try {
      setLoader(true);
      const params = {
        task_id: t_id,
      };
      const response = await getTimeLogData(params);
      setActivityList(response.data.data || []);
    } catch (error) {
    } finally {
      setLoader(false);
    }
  };
  return (
    <ModalContext.Provider
      value={{
        isCommentsModalOpen,
        openCommentsModal,
        closeCommentsModal,
        isCardModalOpen,
        openCardModal,
        closeCardModal,
        isTimeLogModalOpen,
        openTimeLogModal,
        closeTimeLogModal,
        closeModal,
        handleToggalModalState,
        handleTimeLogOpen,
        cardId,
        setCardId,
        fetchTaskDetails,
        eta,
        setETA,
        taskDetails,
        setTaskDetails,
        loader,
        setLoader,
        activityList,
        setActivityList,
        getActivityLog, commentList, setCommentList, addComment, userId, setUserId, userReportingTo, setUserReportingTo, userName, setUserName
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
export const useModal = () => useContext(ModalContext);
