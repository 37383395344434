export const transformData = (inputData) => {
  const transformedData = [];

  const desiredTitles = [
    "To Do",
    "In Progress",
    "On Hold",
    "Done by member (In Review)",
    "Completed/ Approved",
  ];

  // If inputData is empty, create default sections with empty arrays
  if (inputData.length === 0) {
    for (const title of desiredTitles) {
      transformedData.push({ title, items: [] });
    }
    return transformedData;
  }
  const sections = desiredTitles.reduce((obj, title) => {
    obj[title] = [];
    return obj;
  }, {});
  const groupedTasks = inputData.reduce((groups, task) => {
    let status = task.task_current_status;
    if (!groups[status]) {
      groups[status] = [];
    }
    groups[status].push(task);
    return groups;
  }, {});
  for (const [status, tasks] of Object.entries(groupedTasks)) {
    sections[status] = tasks.map((task) => ({
      title: task.task_title,
      task_status_cycle: status,
      count: task.total_comments,
      priority:
        task.task_priority === "H"
          ? "High"
          : task.task_priority === "M"
            ? "Medium"
            : "Low",
      description: task.task_details,
      task_id: task.task_id,
      user_image: task.user_image,
      task_assign_to: task.task_assigned_to,
      task_owner: task.task_owner,
      task_when_changed: task.task_when_changed,
      differenceInDays: task.differenceInDays,
      task_update_days : task.task_update_days ,
      task_current_status:
        task.task_current_status,
      estimation_Date: task.estimation_Date,
      task_assigned_to_id: task.task_assigned_to_id,
      task_from: task.task_from,
      stream_name: task.stream_name,
      task_cost: task.task_cost,
      task_time: task.task_time,
      task_owner_id: task.task_owner_id,
      task_assigned_to_name: task.task_assigned_to_name,
      eta_in_date_time : task.eta_in_date_time,
      task_priority : task.task_priority
     }));
  }
  for (const title of desiredTitles) {
    transformedData.push({ title, items: sections[title] });
  }
  return transformedData;
};
