import { Routes, Route, BrowserRouter } from "react-router-dom";
import CreateTasks from "../src/components/CreateTask/createTask";
import "./assets/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import Login from "./components/Auth/Login";
import TeamDashboard from "./components/Dashboard/TeamDashboard";
import MyTasks from "./components/MyTasks/MyTasks";
import Register from "./components/Auth/Register";
// import ProfileUpdate from "./components/Auth/ProfileUpdate";
import { ProtectedRoute } from "./Routes/ProtectedRoutes";
import { ModalProvider } from "./lib/contexts/ModalContext";
import TaskDetailsPage from "./components/MyTasks/TaskDetailsPage";
import TagsCloud from "./components/Tags-Cloud/TagsCloud";
import DetailsTable from "./components/Dashboard/DetailsTable";
import UserDashboard from "./components/Dashboard/UserDashboard";
import { MainProvider } from "./lib/contexts/context";
import Archive from "./components/MyTasks/Archive";
import Analytics from './components/MyTasks/Analytics';
import Reset from './components/Auth/Reset'
import ForgotPassword from "./components/Auth/ForgotPassword";
import SetNewPassword from "./components/Auth/SetNewPassword"
function App() {
  return (
    <div className="App">
      <MainProvider>
        <ModalProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/set-new-password" element={<SetNewPassword />} />
              <Route path="/resetPassword/:token" element={<Reset /> } />
            
              {/* <Route
                path="/set-new-password"
                element={
                  <ProtectedRoute>
                    <SetNewPassword />
                  </ProtectedRoute>
                }
              /> */}
              <Route
                path="/reports"
                element={
                  <ProtectedRoute>
                    <TeamDashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/userdashboard"
                element={
                  <ProtectedRoute>
                    <UserDashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/task"
                element={
                  <ProtectedRoute>
                    <CreateTasks />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/my-tasks"
                element={
                  <ProtectedRoute>
                    <MyTasks />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/task-details"
                element={
                  <ProtectedRoute>
                    <TaskDetailsPage />
                  </ProtectedRoute>
                }
              />
              {/* <Route
                path="/profile"
                element={
                  <ProtectedRoute>
                    <ProfileUpdate />
                  </ProtectedRoute>
                }
              /> */}
              <Route
                path="/tags"
                element={
                  <ProtectedRoute>
                    <TagsCloud />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/details"
                element={
                  <ProtectedRoute>
                    <DetailsTable />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/archive-task"
                element={
                  <ProtectedRoute>
                    <Archive />
                  </ProtectedRoute>
                }
              />
              {/* <Route
                path="/analytics"
                element={
                  <ProtectedRoute>
                    <Analytics />
                  </ProtectedRoute>
                }
              /> */}
        
              <Route
                path="/reset"
                element={
                  <ProtectedRoute>
                    <Reset />
                  </ProtectedRoute>
                }
              />
            </Routes>

          </BrowserRouter>
        </ModalProvider>
      </MainProvider>
    </div>
  );
}

export default App;
