import React, { useEffect, useState } from "react";
import UserStreamChart from "./UserStreamChart.js";
import axios from "axios";
import { API_URL } from "../../lib/network/api";
import Layout from "../layout/Layout";
import UserPriorityChart from "./UserPriorityChart";
import Loader from "../Loader/Loader";

const UserDashboard = () =>{
    const [priorityData, setPriorityData] = useState([]);
    const [streamData, setStreamData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    useEffect(() => {
        setLoader(true);
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
          setScreenHeight(window.innerHeight);
          if (window.innerWidth < 440 || window.innerHeight < 650) {
            window.location.reload();
          }
        }
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
      useEffect(() => {
        setLoader(false);
        const userData = localStorage.getItem('loggedinUser')
        const { user_id } = JSON.parse(userData)

        axios
          .get(`${API_URL}/graph/priority?user_id=${user_id}`)
          .then((response) => {
            setPriorityData(response?.data?.data || []);
          })
          .catch((error) => {
          });
   
        axios
          .get(`${API_URL}/graph/stream?user_id=${user_id}`)
          .then((response) => {
    setStreamData(response.data.data || []);
          })
          .catch((error) => {
          });
      }, []);
      const priorityLables = ['H', 'M', 'L'];
      const calculateTotalTasks = (tasks) => {
        const result = { H: 0, M: 0, L: 0 };
        tasks?.forEach((task) => {
          result.H += parseInt(task.H) || 0;
          result.M += parseInt(task.M) || 0;
          result.L += parseInt(task.L) || 0;
        });
        return result;
      };
    
      const priorityData_toDo = calculateTotalTasks(priorityData.to_do);
      const priorityData_inProgressTotal = calculateTotalTasks(priorityData.in_progress);
      return (
        <Layout>
          <div className="container-fluid">
            <div className="row mt-4"><h4>My pending task by priority</h4>
             
              <div className="col-lg-12">
        
                <UserPriorityChart data={priorityData} lables={priorityLables} todos={priorityData_toDo} inProgress={priorityData_inProgressTotal} />
              </div>
              <div className="col-lg-12 my-4"><h4>My Pending Task By Stream</h4>
                <br />
                <UserStreamChart data={streamData} />
              </div>
            </div>
          </div>
          {loader && <Loader />}
        </Layout>
      );
    };

export default UserDashboard;