import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo.svg";
import { useLocation, useNavigate } from 'react-router-dom';
import logoutImg from "../../assets/images/logoutimg.svg";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeNavItem, setActiveNavItem] = useState('');
  const loggedinUser = localStorage.getItem("loggedinUser");
  const userData = JSON.parse(loggedinUser);

  const handleNavigation = (path, itemName) => {
    navigate(path);
    setActiveNavItem(itemName);
  };

  useEffect(() => {
    const pathname = location.pathname;
    if (pathname.startsWith('/dashboard')) {
      setActiveNavItem('Dashboard');
    } else if (pathname.startsWith('/task')) {
      if (pathname.startsWith('/task-details')) {
        setActiveNavItem('My Tasks');
      } else {
        setActiveNavItem('Activity');
      }
    } else if (pathname.startsWith('/my-tasks')) {
      setActiveNavItem('My Tasks');
    }
  }, [location.pathname]);

  return (
    <header>
      <Navbar expand="lg">
        <div className="container-fluid">
          <Navbar.Brand href="/my-tasks">
            <img src={logo} alt="" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="navbar-nav m-auto mt-0 mb-lg-0">
              <Nav.Link
                href=""
                onClick={() => handleNavigation("/my-tasks", "My Tasks")}
                className={activeNavItem === "My Tasks" ? "active" : ""}
              >
                My Tasks
              </Nav.Link>

              <Nav.Link
                href=""
                onClick={() => handleNavigation("/task", "Activity")}
                className={activeNavItem === "Activity" ? "active" : ""}
              >
                Create Task
              </Nav.Link>
              <NavDropdown
                title="Reports"
                id="collasible-nav-dropdown"
                className={activeNavItem === "Team Dashboard" || activeNavItem === "User Dashboard" ? "active" : ""}
              >
                <NavDropdown.Item
                  onClick={() => handleNavigation("/reports", "Team Dashboard")}
                  className={activeNavItem === "Team Dashboard" ? "active" : ""}
                >
                  Team Dashboard
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => handleNavigation("/userdashboard", "User Dashboard")}
                  className={activeNavItem === "User Dashboard" ? "active" : ""}
                >
                  User Dashboard
                </NavDropdown.Item>
              </NavDropdown>
              {/* <Nav.Link
  href=""
  onClick={() => handleNavigation("/analytics", "Analytics")}
  className={activeNavItem === "Analytics" ? "active" : ""}
>
  Analytics
</Nav.Link> */}
              <Nav.Link
                href=""
                onClick={() => handleNavigation("/tags", "Tags Cloud")}
                className={activeNavItem === "Tags Cloud" ? "active" : ""}
              >
                Tags Cloud
              </Nav.Link>
            </Nav>
            {/* <a href="#" className="top-userlink" onClick={() => handleNavigation('/profile', "header")}>
              <img src={userData?.image} alt="" className="Img-round me-1" />
              {userData?.user_name}
            </a> */}
            <a href="#" className="top-userlink" >
              <img src={userData?.image} alt="" className="Img-round me-1" />
              {userData?.user_name}
            </a>

            <NavDropdown
              title={<img src={logoutImg} alt="" />}
              id="collasible-nav-dropdown"
              className="logoutbtn"
            >
              {/* <NavDropdown.Item
                className="border-0"
                onClick={() => {
                  // localStorage.clear();
                  navigate("/reset");
                }}
              >
                Reset password
              </NavDropdown.Item> */}
              <NavDropdown.Item
                className="border-0"
                onClick={() => {
                  localStorage.clear();
                  navigate("/");
                }}
              >
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </header>
  );
};

export default Header;
