import React, { useEffect, useRef, useState } from "react";
import chat from "../../assets/images/chat.png";
import Timer from "../../assets/images/timer.png";
import trash from "../../assets/images/trash.svg";
import CustomModal from "../CustomComponents/CustomModal";
import closeCircle from "../../assets/images/close-circle.svg";
import { postAComment } from "../../lib/network/api";
import { ToastContainer } from "react-toastify";
import { useModal } from "../../lib/contexts/ModalContext";
import ActivityTable from "./ActivityTable";
import Loader from "../../components/Loader/Loader";
import DetailModalContent from "./DetailModalContent";
import { deleteTask } from "../../lib/network/api";
import { useNavigate } from "react-router-dom";
import user from "../../assets/images/user-icon.png"
import { position } from "stylis";
import { logDOM } from "@testing-library/react";

const TaskCard = ({
  task, provided, userId, userName, streamsData, userReportingTo, getMyTaskList, isDragging
}) => {
  const { priority, description, count, title, task_id, user_image, task_owner, task_owner_id, task_update_days,
    differenceInDays, task_assign_to, latestDate, stream_name, task_cost, task_time } = task;

  const { isCardModalOpen, openCardModal } = useModal();
  const { isTimeLogModalOpen, openTimeLogModal, closeModal, cardId, setCardId, taskDetails, fetchTaskDetails,
    addComment, activityList, commentList, setCommentList, setActivityList, getActivityLog } = useModal();
  const stream = streamsData?.find(stream => stream.stream_id === task.stream_id);
  const tasksCardRef = useRef(null);
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [commentCount, setCommentCount] = useState(0);
  const [isCommentModalOpen, setCommentModalOpen] = useState(false);
  const [showAssignedToMe, setShowAssignedToMe] = useState(false);
  const navigate = useNavigate();
  const currentDate = new Date();
  const etaDate = new Date(task.eta_in_date_time);
  let date_time_string = task.eta_in_date_time;
  let date_time_obj = new Date(date_time_string);
  let day = date_time_obj.getUTCDate().toString().padStart(2, '0');
  let month = (date_time_obj.getUTCMonth() + 1).toString().padStart(2, '0');
  const monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];
  let monthName = monthNames[date_time_obj.getUTCMonth()];
  let date_only = `${day} ${monthName}`;

  useEffect(() => {
    if (tasksCardRef.current) {
      if (!isDragging && (showAssignedToMe || task.task_assigned_to_id === userId)) {
        tasksCardRef.current.style.backgroundColor = "#FFF2ED";
      } else {
        tasksCardRef.current.style.backgroundColor = "";
      }
    }
  }, [showAssignedToMe, isDragging, task.task_assigned_to_id, userId]);

  useEffect(() => {
    provided.innerRef(tasksCardRef.current);
  }, [provided]);

  const handleTimeLog = (e, t_id) => {
    e.preventDefault();
    setCardId(t_id);
    getActivityLog(t_id)
    e.stopPropagation();
    openTimeLogModal(true);
  };

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  const handlePostComment = (comment) => {
    setLoader1(true);
    const data = {
      user_id: userId,
      task_id: cardId,
      commentText: comment,
      task_title: title,
      task_assign_to: task.task_assigned_to_id,
      task_owner: task_owner.trim(),
    };
    postAComment(data)
      .then((res) => {
        addComment(cardId);
      })
      .catch((error) => {
      })
      .finally(() => {
        setLoader1(false);
      });
  };

  useEffect(() => {
  }, [commentList])
  const handleComments = () => {
    const newCommentCount = task?.comments?.length;
    setCommentCount(newCommentCount);
    setCommentModalOpen(true);
  };

  const handleCloseCommentModal = () => {
    setCommentModalOpen(false);
  };

  const handleCloseModal = (e) => {
    e.preventDefault();
    closeModal();
  };
  const handleCardClick = (e, t_id) => {
    e.preventDefault();
    navigate(`/task-details?id=${t_id}`);
  };
  // const handleCardClick = (e, t_id) => {
  //   window.open(`/task-details?id=${t_id}`, "_blank");
  // };

  const [reload, setReload] = useState(false);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const handleDelete = async (e, taskId) => {
    e.preventDefault();
    setConfirmationModalOpen(true);
  }

  const confirmDelete = async (e, taskId) => {
    console.log("confirmDelete");
    setLoader1(true);
    try {
      let res = await deleteTask(taskId, setReload);
      getMyTaskList();
      e.stopPropagation();
    } catch (error) {
      console.error("Error deleting task", error);
    } finally {
      setLoader1(false);
    }
    setConfirmationModalOpen(false);
  };
  const cancelDelete = () => {
    setConfirmationModalOpen(false);
  };

  const truncateHtmlString = (htmlString, maxLength) => {
    if (htmlString != null) {
      const doc = new DOMParser().parseFromString(htmlString, "text/html");
      const truncatedText = doc.body.textContent || "";
      return truncatedText.length > maxLength
        ? `${truncatedText.slice(0, maxLength)}...`
        : truncatedText;
    } else {
      return ""
    }
  };

  const truncatedDescription = truncateHtmlString(description, 100);
  const storedValue = localStorage.getItem('loggedinUser');
  let userData = JSON.parse(storedValue)

  return (
    <>
      <div className="position-relative">
        <div {...provided.draggableProps} {...provided.dragHandleProps}>
          <div style={{ 'position': 'absolute', 'right': '10px', 'top': '5px', }} >
            {(task_owner_id === userData?.user_id || task.task_assign_to) && task.task_current_status !== "To Do" && (
              // {task_owner_id === userData?.user_id && task.task_current_status !== "To Do" && (
              <a href="#" className="me-1 timeIcons" onClick={(e) => handleTimeLog(e, task_id)}>
                <img className="timetask_icon" src={Timer} alt="" />
              </a>
            )}
            {task_owner_id === userData?.user_id && (
              <a href="" className="delete-position me-1" onClick={(e) => { handleDelete(e, task.task_id) }}>
                <img src={trash} alt="" width={22} />
              </a>
            )}
            {task_owner_id === userData?.user_id || task.task_assign_to && (
              <a href="" className="taskCount " onClick={(e) => { e.preventDefault(); handleComments(); }} style={{ cursor: "default" }}>
                <img src={chat} alt="" /> <span>{count}</span>
              </a>
            )}
            {isConfirmationModalOpen && (
              <div className="confirmation-modal modal_class" style={{ width: "50%", overflow: "auto", zIndex: 1000 }}>
                <div className="modal-content modal-header">
                  <p>Are you sure you want to delete this card?</p>
                  <div className="button-container">
                    <button onClick={(e) => { confirmDelete(e, task.task_id) }}>Yes, Delete</button>

                    <button onClick={cancelDelete}>Cancel</button>
                  </div>
                </div>
              </div>
            )}
            {task_owner_id === userData?.user_id && (
              <a href="#" className="taskCount" onClick={(e) => { e.preventDefault(); handleComments(); }}
                style={{ cursor: "default" }}
              >
                <img src={chat} alt="" /> <span>{count}</span>
              </a>
            )}
          </div>
          <div
            className="tasks_card"
            onClick={(e) => handleCardClick(e, task_id)}
            ref={(el) => {
              provided.innerRef(el);
              tasksCardRef.current = el;
            }}>
            <div className="card_title mb-1">
              <h4 className="text-danger me-3  m-0">{task_id}</h4>
              {/* <h4 className="text-danger fs-5 m-0">{task_cost  ? `£${task_cost}` : ""}</h4> */}
              {/* <h4 className="text-danger  m-0 me-3">{(task_cost == 0 || task_cost == null) ? "" : `$${task_cost}`}</h4>
              <h4 className="text-danger"> {(task_time == 0 || task_time == null) ? "" : `${task_time} Hrs`}</h4> */}
             <h5 className="me-auto mb-1">
              {title.length > 20 ? `${title.slice(0, 20)}...` : title}
            </h5>
            </div>

           
           
            <h6>{task.stream_name ? task.stream_name : "Unknown Stream"}</h6>
            <p dangerouslySetInnerHTML={{ __html: truncatedDescription }} />
            <div class="manewithdate mb-2">
              <h6 className="assigned_name_Name">
                <a href="" className="img_link" onClick={(e) => e.preventDefault()} style={{ position: "relative", top: "-2px" }}>
                  <img src={user} alt={`user_image`} />
                </a> {task.task_assign_to ? task.task_assign_to : "N/A"}</h6>
              <span
                className={`tag_priority ${priority === "High"
                  ? ""
                  : priority === "Low"
                    ? "bg-grey"
                    : "bg-medium"
                  }`}
              >
                {priority}
              </span>
            </div>

            <div className="last-up-rw">

              <small className="sinceTime" style={{ color: task_update_days >= 5 ? 'red' : 'gray' }}>
              {task_update_days} {task_update_days > 1 ? 'days' : 'day'}
              
              </small>

               {/* <small className="sinceTime" style={{ color: task_update_days >= 5 ? 'red' : 'gray' }}>Here since: {task_update_days}  day </small> */}
               {differenceInDays === 0 && (
                <small className="sinceTime ms-auto" style={{ color: 'gray' }}>LU: Today</small>
              )}
              {differenceInDays === 1 && (
                <small className="sinceTime ms-auto" style={{ color: 'gray' }}>LU: Yesterday</small>
              )}
              {differenceInDays > 1 && (
                <small className="sinceTime ms-auto" style={{ color: differenceInDays >= 5 ? 'red' : 'gray' }}>LU: {differenceInDays} days ago</small>
              )}
              {currentDate >= etaDate ?
                <h6 className="assigned_name ms-auto"> Due: {date_only ? date_only : "N/A"}</h6>
                : <h6 className="assigned_date ms-auto">Due: {date_only ? date_only : "N/A"}</h6>}
             
            </div>

            {/* <div className="last-up-rw"> */}
              {/* <p className="mb-0"> {(task_time == 0 || task_time == null) ? "" : `Est Hrs: ${task_time} Hrs`}</p> */}
              {/* {currentDate >= etaDate ?
                <h6 className="assigned_name ms-auto"> Due: {date_only ? date_only : "N/A"}</h6>
                : <h6 className="assigned_date ms-auto">Due: {date_only ? date_only : "N/A"}</h6>} */}
            {/* </div> */}

            <CustomModal
              isOpen={isTimeLogModalOpen}
              onClose={handleCloseModal}
              title="Time Log Modal"
              footerContent={<button onClick={handleCloseModal}>Close</button>}
            >
              <div
                class="modal-content custom-modal"
                style={{
                  height:
                    activityList.length === 0
                      ? "380px"
                      : activityList.length === 1
                        ? "417px"
                        : activityList.length === 2
                          ? "458px"
                          : activityList.length === 3
                            ? "430px"
                            : "504px",
                  overflow:
                    activityList.length === 0
                      ? "hidden"
                      : activityList.length === 1
                        ? "hidden"
                        : activityList.length === 2
                          ? "hidden"
                          : "auto",
                }}
                onClick={handleModalClick}
              >
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="exampleModalToggleLabel">
                    {`Time Log ${cardId}`}
                  </h1>
                  <a href="" onClick={(e) => handleCloseModal(e)}>
                    <img
                      src={closeCircle}
                      alt=""
                      data-bs-dismiss="modal"
                      className="close-modal-icon"
                    />
                  </a>
                </div>

                <div class="modal-body">
                  <ActivityTable
                    task_id={cardId}
                    userId={userId}
                    activityList={activityList}
                    setActivityList={setActivityList}
                  />
                </div>
              </div>

            </CustomModal>
            {
              <CustomModal
                isOpen={isCardModalOpen}
                onClose={handleCloseModal}
                title="Card Modal"
                onClick={handleModalClick}
                footerContent={<button onClick={handleCloseModal}>Close</button>}
              >
                <DetailModalContent
                  closeModal={handleCloseModal}
                  taskId={cardId}
                  userName={userName}
                  userReportingTo={userReportingTo}
                  task={task}
                  commentList={commentList}
                  handlePostComment={handlePostComment}
                  loader1={loader1}
                  handleTimeLog={handleTimeLog}
                  taskDetails={taskDetails}
                  fetchTaskDetails={fetchTaskDetails}
                />
              </CustomModal>
            }
            <ToastContainer />
            {loader1 && <Loader />}
          </div>
        </div>
      </div>
    </>
  );
};
export default TaskCard;

